var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"image":_vm.item.image,"icon":'user',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('accounts.show.active') : _vm.$t('accounts.show.inactive'),"subData":[
                ],"buttons":[
                  {title: _vm.$t('accounts.show.modulation'), icon: 'edit', link: _vm.path+'/'+_vm.$route.params.id+'/edit', role: _vm.$user.admin || _vm.$user.role.accounts_edit} ]}}),_c('div',{staticClass:"col-12"},[_c('showFile')],1),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('accounts.show.Background'),"cloumns":{
                firstCloum: [
                  {icon: 'user', title: _vm.$t('accounts.show.accountName'), value: _vm.item.name},
                  {icon: 'dollar-sign', title: _vm.$t('accounts.show.Balance'), value: _vm.$moneyFormat(( _vm.$option.balance_type == 1 ? _vm.item.balance * -1 : _vm.item.balance )) }
                ],
                scondCloum: [
                  {icon: 'dollar-sign', title: _vm.$t('accounts.show.OpeningBalance'), value: _vm.$moneyFormat(_vm.item.opening_balance)},
                  {icon: 'toggle-on', title: _vm.$t('accounts.show.accountstatus'), value: _vm.item.stat == 1 ? _vm.$t('allerts.effective') : _vm.$t('allerts.Ineffective')} ]
              }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('accounts.show.BillingAddress'),"cloumns":{
                firstCloum: [
                  {icon: 'map-marker-alt', title: _vm.$t('accounts.show.Address'), value: _vm.item.address1},
                  {icon: 'location-arrow', title: _vm.$t('accounts.show.TheSide'), value: _vm.item.entity},
                  {icon: 'map-marked', title: _vm.$t('accounts.show.ZipCode'), value: _vm.item.zip} ],
                scondCloum: [
                  {icon: 'map-marker-alt', title: _vm.$t('accounts.show.TheSequel'), value: _vm.item.address2},
                  {icon: 'city', title: _vm.$t('accounts.show.City'), value: _vm.item.city},
                  {icon: 'globe-americas', title: _vm.$t('accounts.show.State'), value: _vm.item.country} ]
              }}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('accounts.show.CommunicationInformation'),"cloumns":{
              firstCloum: [
                {icon: 'phone', title: _vm.$t('accounts.show.MobileNumber'), value: _vm.item.mobile2? _vm.item.mobile + ' - ' + _vm.item.mobile2 : _vm.item.mobile} ],
              scondCloum: [
                {icon: 'at', title: _vm.$t('accounts.show.Email'), value: _vm.item.email } ]
            }}}),(_vm.$site.accountsDeposits_allow && (_vm.$user.admin || _vm.$user.role.accounts_deposits_show))?_c('accountsDeposits',{staticClass:"col-12"}):_vm._e(),(_vm.$site.accountsWithdrawals_allow && (_vm.$user.admin || _vm.$user.role.accounts_withdrawals_show))?_c('accountsWithdrawals',{staticClass:"col-12"}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }