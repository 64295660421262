var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.accounts.accountDeposit')))]),(
          _vm.$parent.item.stat == 1 &&
          _vm.$site.invoices_allow &&
          (_vm.$user.admin ||
            _vm.$user.role.invoices_add)
        )?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/accountsDeposits/create?account_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.accounts.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$parent.$t('components.accounts.searchForAnOperation'),"withoutCard":true,"emptyTableText":_vm.$parent.$t('components.accounts.ThereAreCurrentlyNoDeposits'),"filter":{account_id: _vm.$route.params.id},"emptyTableSubText":_vm.$parent.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
        {
          column: 'code',
          title: _vm.$parent.$t('components.accounts.Process'),
          type: 'mainLink',
          sort: true,
        },
        {
          column: 'account_id',
          title: _vm.$t('components.accounts.accountName'),
          type: 'link',
          to: 'account',
          sort: true,
          link: true,
        },
        {
          column: 'safe_id',
          title: _vm.$t('components.accounts.Treasury'),
          type: 'link',
          to: 'safe',
          sort: true,
          link: true,
        },
        {
          column: 'date',
          title: _vm.$parent.$t('components.accounts.date'),
          type: 'text',
          sort: true,
        },
        {
          column: 'description',
          title: _vm.$parent.$t('components.accounts.AndThatIsAbout'),
          type: 'text',
          sort: true,
        },
        {
          column: 'cost',
          title: _vm.$parent.$t('components.accounts.cost'),
          type: 'text',
          sort: true,
        },
        {
          column: 'options',
          title: _vm.$parent.$t('components.accounts.actions'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role:
                _vm.$user.admin ||
                _vm.$user.role.invoices_edit,
            },
            { name: 'outLink', role: true },
            { name: 'printInvoice', role: true },
            { name: 'download', role: true },
            {
              name: 'delete',
              role:
                _vm.$user.admin ||
                _vm.$user.role.invoices_delete,
            } ],
        } ],"deleteText":{
        attention: _vm.$parent.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem:
          _vm.$parent.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$parent.$t('allerts.close'),
        confirm: _vm.$parent.$t('allerts.confirm'),
        loading: _vm.$parent.$t('allerts.loading'),
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }