<template>

  <div class="container-fluid">
    <div class="row">
      <showHeader class="col-12 mt-3"
                  :image="item.image"
                  :icon="'user'"
                  :title="item.name"
                  :stat="item.stat"
                  :statText="item.stat ? $t('accounts.show.active') : $t('accounts.show.inactive')"
                  :subData="[
                  ]"
                  :buttons="[
                    {title: $t('accounts.show.modulation'), icon: 'edit', link: path+'/'+$route.params.id+'/edit', role: $user.admin || $user.role.accounts_edit},
                  ]"/>

      <div class="col-12">
        <showFile />
      </div>

      <showCard class="col-12"
                :title="$t('accounts.show.Background')"
                :cloumns="{
                  firstCloum: [
                    {icon: 'user', title: $t('accounts.show.accountName'), value: item.name},
                    {icon: 'dollar-sign', title: $t('accounts.show.Balance'), value: $moneyFormat(( $option.balance_type == 1 ? item.balance * -1 : item.balance )) }
                  ],
                  scondCloum: [
                    {icon: 'dollar-sign', title: $t('accounts.show.OpeningBalance'), value: $moneyFormat(item.opening_balance)},
                    {icon: 'toggle-on', title: $t('accounts.show.accountstatus'), value: item.stat == 1 ? $t('allerts.effective') : $t('allerts.Ineffective')},
                  ]
                }"/>
                

     <showCard class="col-12"
                :title="$t('accounts.show.BillingAddress')"
                :cloumns="{
                  firstCloum: [
                    {icon: 'map-marker-alt', title: $t('accounts.show.Address'), value: item.address1},
                    {icon: 'location-arrow', title: $t('accounts.show.TheSide'), value: item.entity},
                    {icon: 'map-marked', title: $t('accounts.show.ZipCode'), value: item.zip},
                  ],
                  scondCloum: [
                    {icon: 'map-marker-alt', title: $t('accounts.show.TheSequel'), value: item.address2},
                    {icon: 'city', title: $t('accounts.show.City'), value: item.city},
                    {icon: 'globe-americas', title: $t('accounts.show.State'), value: item.country},
                  ]
                }"/>

    <showCard class="col-12"
              :title="$t('accounts.show.CommunicationInformation')"
              :cloumns="{
                firstCloum: [
                  {icon: 'phone', title: $t('accounts.show.MobileNumber'), value: item.mobile2? item.mobile + ' - ' + item.mobile2 : item.mobile},
                ],
                scondCloum: [
                  {icon: 'at', title: $t('accounts.show.Email'), value: item.email },
                ]
              }"/>
      <accountsDeposits class="col-12" v-if="$site.accountsDeposits_allow && ($user.admin || $user.role.accounts_deposits_show)"/>
      <accountsWithdrawals class="col-12" v-if="$site.accountsWithdrawals_allow && ($user.admin || $user.role.accounts_withdrawals_show)"/>

    </div>
  </div>

</template>
<script>

  import axios from 'axios'

  import showHeader from '../elements/show/showHeader.vue'
  import showCard from '../elements/show/showCard.vue'
  import showBoxs from '../elements/show/showBoxs.vue'
  import accountsDeposits from './components/accountsDeposits.vue'
  import accountsWithdrawals from './components/accountsWithdrawals.vue'

  import showFile from '../elements/show/showFile.vue';


  export default {
    data() {
      return {
        path: '/accounts',
        loaded: false,
        item: {}
      }
    },
    mounted() {
      this.getitem()
    },
    methods: {
      getitem() {
        var item = this.$database.accounts.find(el => el.id == this.$route.params.id)
        if (item) {this.item = item}
        axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id)).then(response => {
          this.item = response.data
          this.loaded = true
        })
      },
    },
    components: {
      showHeader,
      showCard,
      showBoxs,
      accountsDeposits,
      accountsWithdrawals,
      showFile
    }
  }

</script>
